<template>
  <section class="notAllowed">
    <div class="notAllowed__body">
      <div class="notAllowed__container">
        <art dir="top" />
        <section class="notAllowed__inner">
          <h2 class="notAllowed__title">
            <div class="d">{{ msg.title }}</div>
          </h2>
          <p class="notAllowed__msg">{{ msg.text }}</p>
          <div class="options">
            <a class="notAllowed__btn button" @click="goBakc">Volver</a>
          </div>
        </section>
        <art dir="bottom" />
      </div>
    </div>
  </section>
</template>

<script>
  import art from "@/common/art.vue";
  import { getHost } from "@/common/app.main.js";
  export default {
    components: { art },
    data: function() {
      return {
        data: "",
        msg: "",
        autologinHost: "",
      };
    },
    methods: {
      goBakc: function() {
        this.to = this.$route.query.to || this.autologinHost;

        if (this.to.startsWith("http")) {
          window.location.href = this.to;
          return;
        }

        this.$router.push(this.to);
      },
    },
    beforeMount: function() {
      this.autologinHost = getHost();
      this.data = this.$route.query.msg || {};
      this.msg = {
        title: this.data.title || "Acceso denegado",
        text: this.data.text || "No tiene permitido el acceso a esta area.",
        to: this.data.to || this.autologinHost,
      };
    },
  };
</script>

<style lang="scss">
  .notAllowed {
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $white;
    &-body {
      width: 520px;
      max-width: 768px;
    }
    &__container {
      height: 90vh;
      max-height: 800px;
      padding: 1.5rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      background: $white;
      border-radius: 1.2rem/2;
      box-shadow: 0 2px 5px #d8d8d8;
      overflow: hidden;
    }
    &__inner {
      max-width: 500px;
      padding: 1.5rem * 2;
      margin: 1.5rem * 2 auto;
      z-index: 2;
    }
    &__msg {
      margin: 1.5rem * 1.5 auto;
    }
    &__btn {
      font-family: $first_font;
      outline: none;
      border: none;
      font-size: 14px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.36);
      border-radius: 2px;
      background-color: $primary-color;
      color: white;
      padding: 10px 30px;
      cursor: pointer;
      &:hover {
        text-decoration: none;
        color: $white;
      }
      &:active {
        opacity: 0.5;
      }
    }

    .options {
      margin: 1.5rem * 2 auto;
    }

    .options .button {
      display: block;
    }

    @media (min-width: 768px) {
      &-body {
        padding: 1.5rem;
      }
    }
  }
</style>
